import React from "react"
import Layout from "../../components/Layout"
import { blog } from "../../styles/blog.module.css"
import { Link, graphql } from "gatsby"

const Blog = ({ data }) => {
  return (
    <Layout pageTitle="Blog Posts">
      <div className={blog}>
        <h2>Blog Posts</h2>
        <h3>Some fresh news from us</h3>
      </div>
      <div>
        {data.allMdx.nodes.map(node => (
          <article key={node.id}>
            <h2>
              <Link to={`/blog/${node.slug}`}>{node.frontmatter.title}</Link>
            </h2>
            <p>
              Posted on : {node.frontmatter.date} by: {node.frontmatter.author}{" "}
            </p>
          </article>
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          title
          author
          date(formatString: "DD MMMM YYYY")
        }
        id
        slug
      }
    }
  }
`
export default Blog
